import React from "react"
import { mediaQueries } from "../../utils/presets"
import { SkipNavTarget } from "../shared/components/SkipNav"

import {
  HeaderHeight,
  HeaderPaddingTopDesktop,
} from "../shared/constants/layout"

const pullBelowHeaderStyles = {
  position: `relative`,
  marginTop: `-${HeaderHeight}`,
  [mediaQueries.phablet]: {
    marginTop: `calc((${HeaderPaddingTopDesktop} + ${HeaderHeight}) * -1)`,
  },
}

const PullBelowHeader = ({ children }) => (
  <div css={{ ...pullBelowHeaderStyles }}>
    <SkipNavTarget />
    {children}
  </div>
)

export default PullBelowHeader
