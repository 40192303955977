/* @jsx jsx */
import { jsx } from "theme-ui"
import { Heading } from "gatsby-interface"
import pattern from "../../../../assets/silly-site-challenge-pattern-light.svg"
import Markdown from "../../../CustomPageLayout/components/Markdown"

export default ({ data }) => {
  return (
    <div
      sx={{
        maxWidth: `100%`,
        mx: [0, 0, 0, 8],
        backgroundImage: `url(${pattern}), linear-gradient(355.68deg, rgba(69, 37, 117, 0.06) 48.24%, rgba(85, 44, 133, 0.028) 99.62%)`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        borderRadius: `16px`,
        pt: [`1rem`, `1rem`, `1rem`, `5rem`],
        // This needs to be bigger than the negative margin in SillySiteSignupForm
        pb: [`1rem`, `1rem`, `1rem`, `11.5rem`],
        px: [`0.5rem`, `1rem`, `2rem`, `6rem`],
        textAlign: `center`,
      }}
    >
      {data.content.map(block => {
        if (block.__typename === "ContentfulHeading")
          return (
            <Heading
              as="h2"
              tone="BRAND"
              sx={{
                fontSize: `2em`,
                fontWeight: 700,
                letterSpacing: `-1px`,
              }}
            >
              {block.text.text}
            </Heading>
          )
        if (block.__typename === "ContentfulMarkdown")
          return (
            <Markdown
              sx={{
                marginTop: `1.5em`,
                fontFamily: `sans`,
              }}
              data={block}
            />
          )
        return null
      })}
    </div>
  )
}
