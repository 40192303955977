/* @jsx jsx */
import { jsx } from "theme-ui"
import { LinkButton } from "gatsby-interface"
import Markdown from "../../../CustomPageLayout/components/Markdown"
import { MdArrowForward } from "react-icons/md"
export default function SillySiteWhatToKnow({ data }) {
  return (
    <div
      css={theme => ({
        backgroundColor: theme.colors.purple[5],
        borderWidth: "1px",
        borderColor: theme.colors.purple[60],
        borderRadius: "12px",
        borderStyle: "solid",
        display: "grid",
        gridTemplateColumns: `1fr`,
        gridTemplateRows: `auto`,
        gridGap: theme.space[10],
        gridTemplateAreas: `
              "body"
              "cta" 
              "image"`,
        padding: `${theme.space[8]} ${theme.space[7]}`,
        margin: `${theme.space[5]}`,

        [theme.mediaQueries.tablet]: {
          gridTemplateColumns: `1fr auto`,
          gridTemplateRows: `1fr auto`,
          gridTemplateAreas: `
            "body image"
            "cta cta"`,
          margin: `0 auto`,
          padding: `${theme.space[10]} 150px`,
          maxWidth: 1036,
        },
      })}
    >
      {data.content.map(block => {
        switch (block.__typename) {
          case `ContentfulMarkdown`:
            return (
              <div sx={{ gridArea: `body` }}>
                <Markdown
                  css={theme => ({
                    whiteSpace: `unset`,
                    fontFamily: theme.fonts.body,

                    li: {
                      margin: 0,
                    },
                    "ul,ol": {
                      padding: 0,
                      marginBottom: theme.space[7],
                      marginTop: theme.space[7],
                    },

                    h2: {
                      marginBottom: theme.space[8],
                    },
                  })}
                  data={block}
                />
              </div>
            )
          case `ContentfulSvg`: {
            return (
              <div
                css={{
                  gridArea: `image`,
                  placeItems: `center`,
                  alignItems: `center`,
                  display: `flex`,
                }}
              >
                <div
                  css={theme => ({
                    margin: `auto`,
                    transform: `translateX(20%)`,
                    [theme.mediaQueries.desktop]: {
                      width: 242,
                      transform: `none`,
                    },
                  })}
                  role="img"
                  dangerouslySetInnerHTML={{ __html: block.image.svg.content }}
                  alt="checklist"
                />
              </div>
            )
          }
          case `ContentfulCta`:
            return (
              <div css={{ gridArea: `cta`, display: `flex` }}>
                <LinkButton
                  to={block.href}
                  sx={{ margin: `auto` }}
                  rightIcon={<MdArrowForward />}
                >
                  {block.anchorText}
                </LinkButton>
              </div>
            )

          default:
            return <p>{block.__typename}</p>
        }
      })}
    </div>
  )
}
