import SillySiteSignupForm from "./SillySiteSignupForm"
import SillySiteQAndA from "./SillySiteQAndA"
import SillySiteIntro from "./SillySiteIntro"
import SillySiteWhatToKnow from "./SillySiteWhatToKnow"
const all = {
  SillySiteSignupForm,
  SillySiteIntro,
  SillySiteQAndA,
  SillySiteWhatToKnow,
}

export {
  SillySiteSignupForm,
  SillySiteIntro,
  SillySiteQAndA,
  SillySiteWhatToKnow,
  all,
}
