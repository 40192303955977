/* @jsx jsx */
import { jsx } from "theme-ui"
import pattern from "../../../../assets/silly-site-q-and-a.webp"
import shapes from "../../../../assets/silly-site-shapes.svg"
import award from "../../../../assets/silly-site-award.svg"
import swag from "../../../../assets/silly-site-swag.svg"
import { Heading, LinkButton } from "gatsby-interface"
import Markdown from "../../../CustomPageLayout/components/Markdown"
import { MdArrowForward } from "react-icons/md"

export default function SillySiteQAndA({ data }) {
  return (
    <div
      css={theme => ({
        margin: `${theme.space[10]} auto`,
        backgroundColor: theme.colors.purple[90],
        backgroundPosition: "top",
        backgroundImage: `
        linear-gradient(
          rgba(54, 32, 102, 0.75),
          rgba(54, 32, 102, 0.75)
        ),
        url(${pattern})`,
        padding: theme.space[5],
        borderRadius: `12px`,
        backgroundRepeat: `no-repeat`,
        maxWidth: 1036,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        width: "100%",
        color: `white`,

        [theme.mediaQueries.tablet]: {
          padding: `${theme.space[10]} 100px`,
        },

        [theme.mediaQueries.desktop]: {
          padding: `${theme.space[10]} 150px`,
        },
      })}
    >
      {data.content.map(block => {
        switch (block.__typename) {
          case "ContentfulHeading":
            return (
              <Heading
                as="h2"
                css={theme => ({
                  fontSize: theme.fontSizes[10],
                  background: `linear-gradient(#B072D3, #F6EDFA)`,
                  backgroundClip: `text`,
                  "-webkit-background-clip": `text`,
                  textFillColor: `transparent`,
                  "-webkit-text-fill-color": "transparent",
                  color: "white",
                  marginBottom: theme.space[7],
                  // pb: 9,
                })}
              >
                {block.text?.text}
              </Heading>
            )

          case "ContentfulMarkdown":
            return (
              <Markdown
                css={theme => ({
                  fontFamily: theme.fonts.body,
                  fontSize: theme.fontSizes[4],
                  whiteSpace: "inherit",
                  ul: {
                    margin: 0,
                  },
                  hr: {
                    backgroundColor: "white",
                  },
                  li: {
                    marginBottom: theme.space[9],
                    padding: `0 ${theme.space[12]}`,
                    minHeight: "60px",
                    lineHeight: 1.5,
                    listStyle: "none",
                    backgroundRepeat: `no-repeat`,
                  },
                  "li:nth-child(1)": {
                    backgroundImage: `url(${shapes})`,
                  },
                  "li:nth-child(2)": {
                    backgroundImage: `url(${award})`,
                  },
                  "li:nth-child(3)": {
                    backgroundImage: `url(${swag})`,
                  },
                })}
                data={block}
              />
            )

          case "ContentfulCta":
            return (
              <LinkButton
                href={block.href}
                css={theme => ({ marginTop: theme.space[10] })}
                rightIcon={<MdArrowForward />}
              >
                {block.anchorText}
              </LinkButton>
            )
          default:
            return <p>Unknown block {block.__typename}</p>
        }
      })}
    </div>
  )
}
