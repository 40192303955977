/* @jsx jsx */
import { jsx } from "theme-ui"

import background from "../../assets/silly-site-background.svg"

import {
  CustomPageLayout,
  ContentRenderer,
  ComponentsProvider,
  baseComponents,
} from "../CustomPageLayout"
import SillySiteHeader from "./components/SillySiteHeader"

import { all as sillySiteChallengeComponents } from "./components"

const components = {
  ...baseComponents,
  ...sillySiteChallengeComponents,
}

export function SillySitePage({ seo, location, content }) {
  return (
    <CustomPageLayout invertHeader seo={seo} location={location}>
      <ComponentsProvider value={components}>
        <SillySiteHeader />
        <div
          sx={{
            background: `url(${background}) top center no-repeat`,
          }}
        >
          <div
            css={theme => ({
              maxWidth: `1243px`,
              margin: `0 auto`,
              [theme.mediaQueries.desktop]: { padding: `${theme.space[12]} 0` },
            })}
          >
            <ContentRenderer content={content} />
          </div>
        </div>
      </ComponentsProvider>
    </CustomPageLayout>
  )
}
