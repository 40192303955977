/* @jsx jsx */
import { jsx } from "theme-ui"
import SillySiteChallengeLogo from "../SillySiteChallengeLogo"
import PullBelowHeader from "../../../containers/pull-below-header"
import pattern from "../../../../assets/silly-site-challenge-pattern.svg"

export default () => (
  <PullBelowHeader>
    <div
      sx={{
        backgroundImage: `url(${pattern})`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundColor: `purple.80`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        paddingTop: [`6rem`, `6rem`, `12rem`],
        paddingBottom: 3,
        color: `white`,
        px: `10%`,
      }}
    >
      <SillySiteChallengeLogo sx={{ maxHeight: `28rem`, maxWidth: `100%` }} />
      <p
        sx={{
          textTransform: `uppercase`,
          marginTop: 6,
          fontSize: `16px`,
          letterSpacing: `0.08em`,
        }}
      >
        hosted by Gatsby
      </p>
    </div>
  </PullBelowHeader>
)
